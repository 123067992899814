import './style.scss'
import { IonCol, IonGrid, IonRow, IonText } from '@ionic/react'
import CertificateTalentCard from '../../CertificateTalentCardMedium'
import getSignature from '../getSignature'
import siegel from '../../../assets/img/certified_pos.png'
import { QRCode } from 'react-qrcode-logo'
import { Trans, useTranslation } from 'react-i18next'
import { type CertificateProps } from '../types'
import getKey from '../getKey'

const JMTCertificate = (props: CertificateProps) => {
  const { t } = useTranslation()
  const position = props.certificate.talentClass === 2 ? 'goalie.' : ''

  return (
        <>
            <div className='jmt-certificate-wrapper' id="certificate-page-1">
                <div className='stars'>
                    {[...Array(5)].map((_, i) => (
                        <div className='star' key={`star-${i}`} />
                    ))}
                </div>
                <IonGrid
                    className='certificate'
                >
                    {/* MAIN */}
                    <IonRow className='certificate__main__content__header__title__headline--mobile'>
                        <IonCol>
                            <IonText>
                                <h3>
                                    {props.header.intro}
                                </h3>
                            </IonText>
                        </IonCol>
                        <IonCol>
                            <IonText>
                                <h1 className='bolder'>
                                    Deine Talentcard
                                </h1>
                            </IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow
                        className='certificate__main'
                    >
                        <IonCol
                            className='certificate__main__talent-card-wrapper'>
                            <CertificateTalentCard
                                certificate={props.certificate}
                                clubImg={props.clubImg}
                                schoolImg={props.schoolImg}
                            />
                        </IonCol>

                        <IonCol
                            className='certificate__main__content'>
                            <IonGrid>
                                <IonRow
                                    className='certificate__main__content__header hide-small'
                                >
                                    <IonCol
                                        className='certificate__main__content__header__title'
                                    >
                                        <IonRow className='certificate__main__content__header__title__headline'>
                                            <IonCol>
                                                <IonText>
                                                    <h3>
                                                        {props.header.intro}
                                                    </h3>
                                                </IonText>
                                            </IonCol>
                                            <IonCol>
                                                <h1 className='bolder'>
                                                    Deine Talentcard
                                                </h1>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className='certificate__main__content__header__title__name'>
                                            <IonText>
                                                <h1 className='name'>
                                                    {props.certificate.caption}
                                                </h1>
                                            </IonText>
                                        </IonRow>
                                    </IonCol>
                                    <IonCol
                                        className='certificate__main__content__header__img-wrapper display-flex display-flex-column ion-text-justify hide-small'
                                    >
                                        {props.schoolImg && (
                                            <img
                                                src={props.schoolImg}
                                                alt='camp'
                                            />
                                        )}
                                        <QRCode value="https://joinmytalent.com" />
                                    </IonCol>
                                </IonRow>

                                <IonRow
                                    className='certificate__main__content__signature'
                                >
                                    <IonCol>
                                        <Trans
                                            i18nKey='certificate.thankYou'
                                            values={{ name: getSignature(props.certificate) }}
                                        >
                                            Introtext und so
                                            <span className='certificate__main__content__signature__name'>
                                                CampName
                                            </span>
                                        </Trans>
                                    </IonCol>
                                </IonRow>
                                <IonRow
                                    className='certificate__main__signature'
                                >
                                    <IonCol
                                        size='auto'
                                        className='ion-margin-end ion-padding-end'
                                    >
                                        <img alt='siegel' src={siegel} className='certificate__main__signature__siegel' style={{ height: '90px', minWidth: '90px' }} />
                                    </IonCol>
                                </IonRow>
                                {/* <IonRow */}
                                {/*    className='certificate__main__content__text' */}
                                {/* > */}
                                {/*  <IonCol> */}
                                {/*    {props.text} <a target='_blank' href='http://hsv.joinmytalent.com'>https://hsv.joinmytalent.com</a> */}
                                {/*  </IonCol> */}
                                {/* </IonRow> */}
                            </IonGrid>
                        </IonCol>
                    </IonRow>

                    {/* FOOTER */}
                    {props.footer && (
                        <IonRow
                            className='certificate__footer ion-align-items-center hide-small'>
                            {props.footer.img && (
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-align-items-center'
                                >
                                    <img
                                        className='certificate__footer__img'
                                        src={props.footer.img}
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            )}
                            {props.footer.text && (
                                <IonCol>
                                    <IonText>
                                        {props.footer.text}
                                    </IonText>
                                </IonCol>
                            )}
                            <IonCol
                                size='auto'
                                className='ion-padding-end ion-padding-start'
                            >
                                <img
                                    className='certificate__footer__logo'
                                    src='/assets/icon/logo.svg'
                                    alt='certificate footer logo'
                                />
                            </IonCol>
                        </IonRow>
                    )}

                    {/* SECOND PAGE */}
                </IonGrid>
            </div>

            <div className='jmt-certificate-wrapper' id="certificate-page-2">
                <IonGrid
                    className='certificate'
                >
                    <IonRow
                        className='certificate__page-2'
                    >
                        {/* <IonCol */}
                        {/*  className='certificate__id hide-small' */}
                        {/* > */}
                        {/*  <CertificateTalentCard */}
                        {/*      title={props.talentCardTitle} */}
                        {/*      certificate={props.certificate} */}
                        {/*      clubImg={props.clubImg} */}
                        {/*      schoolImg={props.schoolImg} */}
                        {/*  /> */}
                        {/* </IonCol> */}
                        <IonCol
                            className='certificate__texts'
                        >
                            <h1>{t('certificate.page2.title')}</h1>
                            <IonRow>
                                <IonCol>
                                    {[0, 1, 2, 3].map((i) => (
                                        <div className='certificate__texts__block ion-padding-end' key={i}>
                                            <h5>{t(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                            <div className='certificate__texts__block__description'>
                                                <IonText>
                                                    {t(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                                </IonText>
                                            </div>
                                        </div>
                                    ))}
                                </IonCol>
                                <IonCol>
                                    {[4, 5, 6, 7].map((i) => (
                                        <div className='certificate__texts__block' key={i}>
                                            <h5>{t(`i18n.vote.${position}${i}`)} &nbsp;&nbsp; {props.certificate?.vote[i]}</h5>
                                            <div className='certificate__texts__block__description'>
                                                <IonText>
                                                    {t(`certificate.${i}.${position}${getKey(props.certificate?.vote[i])}`)}
                                                </IonText>
                                            </div>
                                        </div>
                                    ))}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                    {/* FOOTER */}
                    {props.footer && (
                        <>
                            <IonRow
                                className='certificate__footer ion-align-items-center hide-small'>
                                {props.footer.img && (
                                    <IonCol
                                        size='auto'
                                        className='ion-padding-end ion-align-items-center'
                                    >
                                        <img
                                            className='certificate__footer__img'
                                            src={props.footer.img}
                                            alt='certificate footer logo'
                                        />
                                    </IonCol>
                                )}
                                {props.footer.text && (
                                    <IonCol>
                                        <IonText>
                                            {props.footer.text}
                                        </IonText>
                                    </IonCol>
                                )}
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-padding-start'
                                >
                                    <img
                                        className='certificate__footer__logo'
                                        src='/assets/icon/logo.svg'
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow
                                className='certificate__footer ion-align-items-center hide-big'>
                                <IonRow>
                                    {props.footer.text && (
                                        <IonCol>
                                            <IonText>
                                                {props.footer.text}
                                            </IonText>
                                        </IonCol>
                                    )}
                                </IonRow>
                                {props.footer.img && (
                                    <IonCol
                                        className='ion-padding-end ion-align-items-center'
                                    >
                                        <img
                                            className='certificate__footer__img'
                                            src={props.footer.img}
                                            alt='certificate footer logo'
                                        />
                                    </IonCol>
                                )}
                                <IonCol
                                    size='auto'
                                    className='ion-padding-end ion-padding-start'
                                >
                                    <img
                                        className='certificate__footer__logo'
                                        src='/assets/icon/logo.svg'
                                        alt='certificate footer logo'
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </IonGrid>
            </div>
        </>
  )
}

export default JMTCertificate
