import './style.scss'
import '../../components/Camps/Participant.scss'
import { useTranslation } from 'react-i18next'
import Page from '../../components/Page'
import Content from '../../components/Content'
import Header from '../../components/Header'
import {
  CampDocument,
  type Group,
  useCampQuery,
  useCreateGroupMutation,
  useParticipantsQuery,
  useSpecialTalentsQuery,
  useEditCampDirectorMutation,
  type Participant as GqlParticipant,
  useAddMultipleToGroupMutation,
  useMarkedSpecialTalentsQuery,
  useRerunPersistCertificateMutation
} from '../../lib/apollo/types'
import { type RouteComponentProps } from 'react-router'
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSelectOption,
  IonSpinner,
  IonText
} from '@ionic/react'
import Form from '../../components/Form'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { useCallback, useMemo, useState } from 'react'
import FormSelectField from '../../components/Form/FormSelectField'
import getAge from '../../lib/getAge'
import { addCircle, arrowForward, checkmarkCircle, chevronDown, ellipsisVertical, newspaper } from 'ionicons/icons'
import Participant from '../../components/Camps/Participant'
import { useUser } from '../../providers/Auth/hooks'
import Divider from '../../components/Divider'
import useToast from '../../providers/Toast/hooks'
import CollapsibleContent from '../../components/CollapsibleContent'
import clsx from 'clsx'
import GroupCard from '../../components/Camps/GroupCard'
import Protected from '../../components/Protected'
import FormTextField from '../../components/Form/FormTextField'

const dateFormat = new Intl.DateTimeFormat('de-DE', { dateStyle: 'short' })

export interface CampsProps
  extends RouteComponentProps<{
    id: string
  }> { }

const sortGroups = (a: Group, b: Group) => {
  const yearsA = a.participants?.map(participant => getAge(participant.birthday)).filter(Boolean)
  const yearsB = b.participants?.map(participant => getAge(participant.birthday)).filter(Boolean)

  if (!yearsA[0]) return 1
  if (!yearsB[0]) return -1

  if (yearsA[0] === yearsB[0]) {
    return (yearsA[yearsA.length - 1] ?? 0) - (yearsB[yearsB.length - 1] ?? 99)
  }

  return (yearsA[0] ?? 0) - (yearsB[0] ?? 0)
}

const CampPage: React.FC<CampsProps> = ({ match }) => {
  const { t } = useTranslation()
  const user = useUser()
  const [present] = useToast()

  const { data } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: specialTalentsGql } = useSpecialTalentsQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: markedSpecialTalents } = useMarkedSpecialTalentsQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const specialTalents = useMemo(() =>
    specialTalentsGql?.specialTalents.filter(t => !markedSpecialTalents?.markedSpecialTalents.some(mt => mt.participant.id === t.id))
  , [markedSpecialTalents?.markedSpecialTalents, specialTalentsGql?.specialTalents])

  const markedAsSpecialTalents =
    markedSpecialTalents?.markedSpecialTalents
      .filter(mt => mt.isTalent)
      .map(mt => specialTalentsGql?.specialTalents.find(t => t.id === mt.participant.id))
      .filter(Boolean)

  const markedNotAsSpecialTalents =
    markedSpecialTalents?.markedSpecialTalents
      .filter(mt => !mt.isTalent)
      .map(mt => specialTalentsGql?.specialTalents.find(t => t.id === mt.participant.id))
      .filter(Boolean)

  const [editCampDirectorMutation] = useEditCampDirectorMutation()
  const [editCampDirectorOpen, setEditCampDirectorOpen] = useState(false)
  const editCampDirector = useCallback(async (input: { trainerId: string }) => {
    await editCampDirectorMutation({
      variables: {
        input: {
          ...input,
          campId: match.params.id
        }
      }
    })

    setEditCampDirectorOpen(false)
  }, [editCampDirectorMutation, match.params.id])

  const [newOpen, setNewOpen] = useState(false)
  const [createGroupMutation] = useCreateGroupMutation()
  const createGroup = useCallback(async ({ name }: { name: string }) => {
    await createGroupMutation({
      variables: {
        input: {
          campId: match.params.id,
          name
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setNewOpen(false)
  }, [createGroupMutation, match.params.id])

  const myGroups = useMemo(() => {
    return [...data?.camp?.groups ?? []].sort(sortGroups).filter(g => g.trainer?.email === user?.user.email)
  }, [data?.camp?.groups, user?.user.email])

  const groups = useMemo(() => {
    return [...data?.camp?.groups ?? []].sort(sortGroups).filter(g => !myGroups.some(m => m.id === g.id))
  }, [data?.camp?.groups, myGroups])

  const { data: participants, loading } = useParticipantsQuery({
    variables: {
      campId: match.params.id
    }
  })

  const participantsWithoutGroupCount = useMemo(() => {
    return participants?.participants?.filter(p =>
      !data?.camp?.groups?.some(g => g.participants?.find(participant => participant.id === p.id))
    )?.length ?? 0
  }, [participants?.participants, data?.camp?.groups])

  const [talentIds, setTalentIds] = useState<string[]>([])
  const [addOpen, setAddOpen] = useState(false)
  const [selectGroupToAddOpen, setSelectGroupToAddOpen] = useState(false)
  const onTalentItemCLick = useCallback((talentId: string) => {
    if (talentIds.includes(talentId)) {
      setTalentIds(talentIds.filter(id => id !== talentId))
    } else {
      setTalentIds([...talentIds, talentId])
    }
  }, [talentIds])

  const [addTalents] = useAddMultipleToGroupMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })

  const onAddTalentsClick = useCallback(async (group: Group) => {
    await addTalents({
      variables: {
        groupId: group.id,
        campId: match.params.id,
        participantIds: talentIds
      }
    })
    present('Teilnehmer hinzugefügt', 'success')
    setSelectGroupToAddOpen(false)
  }, [addTalents, match.params.id, present, talentIds])

  const groupedParticipantsByAge = useMemo(() => {
    if (!participants?.participants) return {}

    const groupedParticipantsByAge: Record<string, Array<GqlParticipant & { group: string }>> = {}

    const sortedByName = [...participants?.participants ?? []].sort(
      (a, b) => {
        if (a.firstname.localeCompare(b.firstname) === 0) {
          return a.lastname.localeCompare(b.lastname)
        }
        return a.firstname.localeCompare(b.firstname)
      }
    )

    sortedByName.forEach((participant) => {
      const age = getAge(participant.birthday)
      if (!age) return
      // check if participant is already in group

      // check if participant is already in a group
      const groupName = data?.camp?.groups?.find(group => group.participants.find(p => p.id === participant.id))?.name
      if (groupName) return

      if (!groupedParticipantsByAge[age]) {
        groupedParticipantsByAge[age] = []
      }

      groupedParticipantsByAge[age].push({
        ...participant,
        group: groupName ?? ''
      })
    })

    return groupedParticipantsByAge
  }, [data?.camp?.groups, participants?.participants])

  const onParticipantInfoClick = useCallback(() => {
    if ((data?.camp?.groups?.length ?? 0) > 0) {
      setAddOpen(true)
    } else {
      present('Bitte erstelle erst eine Gruppe', 'warning')
    }
  }, [data?.camp?.groups?.length, present])

  const [talentsOpen, setTalentsOpen] = useState(false)

  const [rerunPersistCertificate, { loading: rerunLoading }] = useRerunPersistCertificateMutation()

  const onRegenerateCertificatesClick = useCallback(async () => {
    const campId = data?.camp?.id
    if (!campId) return

    const response = await rerunPersistCertificate({
      variables: {
        campId: +campId
      }
    })
    if (response) {
      present('Zeugnisse erfolgreich generiert', 'success')
    } else {
      present('Oops, irgendwas ist schief gelaufen...', 'danger')
    }
  }, [data?.camp?.id, present, rerunPersistCertificate])

  if (!data?.camp) return null

  return (
    <Page>
      <Header title={data?.camp?.caption ?? t('title.camp')}></Header>
      <Content>
        <div className='camp__info'>
          <div>
            <img src={data.camp.image ?? ''} alt={data.camp.caption ?? 'camp-img'} />
          </div>
          <div>
            <h2>
              {data.camp.caption}
            </h2>
            <div className='camp__info__conent'>
              <div>{data.camp.type}</div>
              <div>{dateFormat.format(new Date(data.camp.start ?? ''))} - {dateFormat.format(new Date(data.camp.end ?? ''))}</div>
              <div>{data.camp.ageMin} - {data.camp.ageMax} Jahre</div>
              <div>{data.camp.participantsCount} Teilnehmer</div>
              <Protected role={'admin'}>
                <Button
                  loading={rerunLoading}
                  onClick={onRegenerateCertificatesClick}
                  disabled={!data.camp.finished}
                  icon={newspaper}
                >
                  Zeugnisse erneut generieren
                </Button>
              </Protected>
              <div>
                <div className='camp__trainer-list'>
                  {(data.camp.campDirector
                    ? (
                      <IonCard className='trainer-card trainer-card--director ion-margin-top ion-no-margin'>
                        <IonIcon src='/assets/icon/trainer.svg' size='large' color='secondary' />
                        <div className='participant-card__caption'>
                          Campleiter:<br />{data.camp.campDirector.firstname} {data.camp.campDirector.lastname}
                        </div>
                        <Button className='trainer-card__button' icon={ellipsisVertical} fill='clear' color='secondary' onClick={() => { setEditCampDirectorOpen(true) }} />
                      </IonCard>
                      )
                    : (
                      <IonCard className='trainer-card trainer-card--director trainer-card--add' onClick={() => { setEditCampDirectorOpen(true) }}>
                        <IonIcon icon={addCircle} size='large' />
                        <div className='participant-card__caption participant-card__caption--select'>Campleiter wählen</div>
                      </IonCard>
                      ))}
                </div>
              </div>
              {participantsWithoutGroupCount === 0
                ? (
                  <div className='camp__info__content__participant-info'>
                    <IonIcon color='success' icon={checkmarkCircle} />
                    {t('camp.participants.groupFinished')}
                  </div>
                  )
                : (
                  <Button className='camp__info__content__participant-info' fill='clear' color='secondary' onClick={onParticipantInfoClick}>
                    {t('camp.participants.needGroup', { count: participantsWithoutGroupCount })}
                  </Button>
                  )}
            </div>
          </div>
        </div>
        <Divider />
        {!!data.camp.groups?.length && (
          <div className='camp__group-list'>
            {myGroups.length > 0 && (
              <>
                {myGroups.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} />)}
                <Divider />
              </>
            )}
            {groups.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} />)}
          </div>
        )}
        {participantsWithoutGroupCount > 0 && (
          <>
            <div>
              <div className='section-description ion-text-justify'>
                <h2
                  className='ion-margin-bottom'
                >
                  {t('camp.createGroup.title')}
                </h2>
                <IonText
                  className='ion-padding-bottom n2br'
                  color='medium'
                >
                  {t('camp.createGroup.description')}
                </IonText>
              </div>
              <IonCard onClick={() => { setNewOpen(true) }} className='camp__add-group'>
                <IonCardContent className='camp__group-card'>
                  <div><IonIcon icon={addCircle} /></div>
                  <div>Neue Gruppe erstellen</div>
                </IonCardContent>
              </IonCard>
            </div>
            <Divider />
          </>
        )}
        {!!specialTalents?.length && (
          <div>
            <h3 className='ion-padding-start'>{t('camp.newMarkedTalents')}</h3>
            <div className='camp__participant-list'>
              {specialTalents?.map(specialTalent => (
                <Participant
                  participant={specialTalent}
                  campId={match.params.id}
                  key={specialTalent.id}
                />
              ))}
            </div>
          </div>
        )}
        {!!markedAsSpecialTalents?.length && (
          <div>
            <h4 className='ion-padding-start'>{t('camp.markedTalents')}</h4>
            <div className='camp__participant-list'>
              {markedAsSpecialTalents?.map(specialTalent => {
                if (!specialTalent) return null
                return (<Participant
                  participant={specialTalent}
                  campId={match.params.id}
                  key={specialTalent.id}
                />
                )
              })}
            </div>
          </div>
        )}
        {!!markedNotAsSpecialTalents?.length && (
          <div>
            <div
              className='display-flex ion-justify-content-between ion-align-items-center talents-collapsible-wrapper'
              onClick={() => { setTalentsOpen(old => !old) }}
            >
              <h4 className='ion-padding-start'>
                {t('camp.rejectedTalents')}
              </h4>
              <IonIcon
                icon={chevronDown}
                className={clsx({ 'talents-collapsible--open': talentsOpen }, 'talents-collapsible')}
              />
            </div>
            <CollapsibleContent open={talentsOpen}>
              <div className='camp__participant-list'>
                {markedNotAsSpecialTalents?.map(specialTalent => {
                  if (!specialTalent) return null
                  return (<Participant
                    participant={specialTalent}
                    campId={match.params.id}
                    key={specialTalent.id}
                  />
                  )
                })}
              </div>
            </CollapsibleContent>
          </div>
        )}
        <div>
          <h3 className='ion-padding-start ion-no-margin'>Trainer</h3>
          <div className='camp__trainer-list'>
            {data.camp.trainers?.map(trainer => (
              <IonCard key={trainer.id} className='trainer-card'>
                <IonIcon src='/assets/icon/trainer.svg' size='large' />
                <div className='trainer__caption'>
                  {trainer.firstname} {trainer.lastname}
                  <br />
                  <a target='_blank' href={`mailto:${trainer.email}`} rel="noreferrer">{trainer.email}</a>
                </div>
              </IonCard>
            ))}
          </div>
        </div>
      </Content>
      <Modal
        modalTitle='Gruppe erstellen'
        size='alert'
        isOpen={newOpen}
        onClose={() => { setNewOpen(false) }}
        onDidDismiss={() => { setNewOpen(false) }}
      >
        <div className='create-group-modal'>
          <Form onSubmit={createGroup}>
            <FormTextField name='name' label={t('camp.createGroup.label')} required />
            <Button type='submit' className='ion-float-end'>Speichern</Button>
          </Form>
        </div>
      </Modal>
      <Modal
        modalTitle='Campleiter wählen'
        size='alert'
        isOpen={editCampDirectorOpen}
        onDidDismiss={() => { setEditCampDirectorOpen(false) }}
      >
        <Form onSubmit={editCampDirector}>
          <FormSelectField required label='Trainer' name='trainerId' className='jmt-select'>
            {data.camp.trainers?.map((t) => (
              <IonSelectOption
                value={t.id}
                key={t.id}
              >
                {t.firstname} {t.lastname}
              </IonSelectOption>
            ))}
          </FormSelectField>
          <Button type='submit' className='ion-float-end'>Speichern</Button>
        </Form>
      </Modal>
      <Modal
        modalTitle='Teilnehmer auswählen'
        isOpen={addOpen}
        onClose={() => { setAddOpen(false) }}
        onDidDismiss={() => { setAddOpen(false) }}
      >
        <div className='ion-padding-start'>
          <IonText color='medium'>
            {t('camp.addParticipantInfo')}
          </IonText>
        </div>
        {loading && (
          <div className='ion-justify-content-center display-flex'>
            <IonSpinner />
          </div>
        )}
        {Object.entries(groupedParticipantsByAge ?? {}).map(([age, participants]) => (
          <div key={age} className='ion-margin-bottom ion-padding-bottom'>
            <h3>{age} Jahre</h3>
            <div className='camp__group-list'>
              {participants.map((participant) => (
                <IonCard style={{ cursor: 'pointer' }} className='participant-card participant-card--full-width' onClick={() => { onTalentItemCLick(participant.id) }} key={participant.id}>
                  <IonIcon src='/assets/icon/logo.svg' size='large' />
                  <div className='participant-card__caption'>
                    {participant.firstname} {participant.lastname} <br />
                    <span className='participant-card__caption__group'>{participant.group}</span>
                  </div>
                  <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}>
                    <IonCheckbox checked={talentIds.includes(participant.id)} onClick={(e) => { e.preventDefault() }} />
                  </div>
                </IonCard>
              ))}
            </div>
          </div>
        ))}
        <IonFab vertical='bottom' horizontal='end' slot='fixed'>
          <IonFabButton onClick={() => {
            setSelectGroupToAddOpen(true)
            setAddOpen(false)
          }}>
            <IonIcon icon={arrowForward} /></IonFabButton>
        </IonFab>
      </Modal>
      <Modal
        modalTitle='Gruppe auswählen'
        isOpen={selectGroupToAddOpen}
        onClose={() => { setSelectGroupToAddOpen(false) }}
        onDidDismiss={() => { setSelectGroupToAddOpen(false) }}
      >
        <div className='ion-padding-start ion-margin-start'>
          <IonText color='medium'>
            {t('camp.selectGroupToAdd')}
          </IonText>
        </div>
        {data?.camp.groups?.map((group) => <GroupCard key={group.id} group={group} campId={match.params.id} onClick={onAddTalentsClick} />)}
      </Modal>
    </Page>
  )
}

export default CampPage
