import { type CertificateProps } from './types'
import JMTCertificate from './JMT'
import RBLCertificate from './RBL'
import RBLCertificate24 from './RBL24'
import BMGCertificate from './BMG'
import { Suspense } from 'react'
import { CERTIFICATE_CHANGE_DATE } from '../../lib/apollo/config'

const Certificate = (props: CertificateProps) => {
  const startDate = props.certificate.camp.start
  const showNewCertificate = startDate && CERTIFICATE_CHANGE_DATE && new Date(startDate).getTime() > parseInt(CERTIFICATE_CHANGE_DATE)

  switch (props.club) {
    case 'bmg': return (
      <Suspense fallback='...loading'>
        <BMGCertificate {...props} clubImg="/assets/bmg/logo.svg" />
      </Suspense>
    )
    case 'rbl':
      if (showNewCertificate) {
        return (
          <RBLCertificate24 {...props} clubImg="/assets/rbl/schule_logo.png" />
        )
      } else {
        return (
          <Suspense fallback='...loading'>
            <RBLCertificate {...props} clubImg="/assets/rbl/schule_logo.png" />
          </Suspense>
        )
      }
    default: return <JMTCertificate {...props} />
  }
}

export default Certificate
